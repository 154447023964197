<template>
  <div id="patrolEdit">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/patrol' }">巡视点管理</el-breadcrumb-item>
          <el-breadcrumb-item>编辑巡视点</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="110px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡视点名称：" prop="patrolName">
                <el-input v-model="form.patrolName" placeholder="请输入巡视点名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡视点编号：" prop="patrolNumber">
                <el-input v-model="form.patrolNumber" placeholder="请输入巡视点编号" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属部门：" prop="departmentId">
                <el-select v-model="form.departmentId" placeholder="请选择所属部门" style="width: 335px">
                  <el-option v-for="item in bumen" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设施位置：" prop="facilityId">
                <el-select v-model="form.facilityId" placeholder="请选择设施位置" style="width: 335px">
                  <el-option v-for="item in sheshi" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="描述说明：">
                <el-input v-model="form.description" placeholder="请输入描述说明"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="form-title">设备信息</div>
          <div style="padding-left: 35px;">
            <el-button @click="openDeviceDrawer" type="text">增加巡视点设备</el-button>
          </div>
          <div class="device-model" v-for="(item, index) in form.patrolDeviceDetails" :key="index">
            <div class="title-close" :style="!item.dShows?'margin:0;border:0;':''">
              <span>
                <span class="iconfont" :class="item.dShows?'el-icon-arrow-up':'el-icon-arrow-down'" style="font-size: 20px;color: #409EFF;" @click="toggleDevice(item)"></span> 
                设备 - {{index + 1}}
              </span>
              <el-button @click="deleteDevice(item, index)" type="text" class="delete-device">删除</el-button>
            </div>
            <el-collapse-transition>
              <div v-if="item.dShows">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="设备别名：">
                      <el-input v-model="item.alias"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="设备识别号：">
                      <el-input v-model="item.ein"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="设备名称：">
                      <el-input v-model="item.deviceName" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="规格参数：">
                      <el-input v-model="item.specification" disabled></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="所属部门：">
                      <el-input v-model="item.departmentName" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="设备类型：">
                      <el-input v-model="item.deviceTypeName" disabled></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="投入使用时间">
                      <el-date-picker
                        style="width: 325px"
                        v-model="item.applyTime"
                        :clearable="false"
                        value-format="timestamp"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 巡检模块 -->
                <div class="patrol-con">
                  <div>
                    <div class="form-title patrol-title">
                      <div style="display: flex;">
                        <span class="iconfont icon-xunjian" style="font-size: 20px;margin-right: 5px;"></span>
                        <span>巡检内容</span>
                      </div>
                      <div>
                        <!-- 是否显示巡检项：<el-switch v-model="item.patrol"></el-switch> -->
                      </div>
                    </div>
                    <div v-show="item.patrol">
                      <div v-for="(pItem, pIndex) in item.patrolRContents" :key="pIndex">
                        <div class="con-item" v-if="pItem.taskType==0">
                          检查内容
                          <el-input v-model="pItem.checkContent" style="width:50%;margin: 0 10px;"></el-input>
                          <span @click="deleteDate(item.patrolRContents, pItem, pIndex)" style="cursor: pointer;color: red;">删除</span>
                        </div>
                      </div>
                      <div class="add-checkContent">
                        <span @click="addCheckContent(item.patrolRContents, item.patrolDeviceId, 0)">自定义添加</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 点检模块 -->
                <div class="patrol-con">
                  <div>
                    <div class="form-title patrol-title">
                      <div style="display: flex;">
                        <span class="iconfont icon-tubiaozhizuomoban-133" style="font-size: 20px;margin-right: 5px;"></span>
                        <span>点检内容</span>
                      </div>
                      <div>
                        是否显示点检项：<el-switch v-model="item.check" @change="closeConItem"></el-switch>
                      </div>
                    </div>
                    <div v-show="item.check">
                      <div v-for="(cItem, cIndex) in item.checkRContents" :key="cIndex">
                        <div class="con-item" v-if="cItem.taskType==1">
                          检查内容
                          <el-input v-model="cItem.checkContent" style="width:50%;margin: 0 10px;"></el-input>
                          <span @click="deleteDate(item.checkRContents, cItem, cIndex)" style="cursor: pointer;color: red;">删除</span>
                        </div>
                      </div>
                      <div class="add-checkContent">
                        <span @click="addCheckContent(item.checkRContents, item.patrolDeviceId, 1)">自定义添加</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 润滑模块 -->
                <div class="patrol-con lube-con">
                  <div>
                    <div class="form-title lube-title">
                      <div style="display: flex;">
                        <span class="iconfont icon-zhouchengrunhuayou" style="font-size: 20px;margin-right: 5px;"></span>
                        <span>润滑内容</span>
                      </div>
                      <div>
                        是否显示润滑项：<el-switch v-model="item.lube" @change="closeLubeItem"></el-switch>
                      </div>
                    </div>
                    <div v-show="item.lube" v-for="(luItem, luIndex) in item.lubeCheckContents" :key="luIndex">
                      <div class="item-title lube-title">
                        <span>润滑部位{{luIndex+1}}</span>
                        <span @click="deleteLube(item.lubeCheckContents, luItem, luIndex)" v-if="luIndex != 0" style="cursor: pointer;color: red;">删除</span>
                        <span class="lube-add" @click="addLube(item.lubeCheckContents, item.patrolDeviceId)" v-else>添加</span>
                      </div>
                      <div class="item-con">
                        <el-row :gutter="50">
                          <el-col :span="8">
                            <el-form-item label="部位名称">
                            <el-input v-model="luItem.position"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="润滑点数">
                            <el-input v-model="luItem.points"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="润滑油型号">
                              <button v-if="!luItem.brand" class="lube-btn" @click.prevent="handleModel(index, luItem, luIndex)">请选择</button>
                              <span v-else class="close-lube-btn">
                                {{luItem.model}}
                                <span class="el-icon-error close-lube" @click="clearLubeOil(index, luItem, luIndex)"></span>
                              </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item label="润滑油品牌">
                              <el-input v-model="luItem.brand" disabled></el-input>
                            </el-form-item>  
                          </el-col>
                          <el-col :span="6">
                            <el-form-item label="润滑油分类">
                              <el-input v-model="luItem.classification" disabled></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item label="润滑油等级">
                              <el-input v-model="luItem.level" disabled></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="50">
                          <el-col :span="8">
                            <el-form-item label="换底油用量" style="position: relative;">
                            <el-input v-model="luItem.replace"></el-input>
                            <span style="position: absolute;right: -20px;top: 0;">kg</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="补加油用量" style="position: relative;">
                            <el-input v-model="luItem.refuel"></el-input>
                            <span style="position: absolute;right: -20px;top: 0;">kg</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <div style="margin-left: 20px;font-weight: bold;">润滑方法</div>
                        <div class="con-item" v-for="(lueItem, lueIndex) in luItem.lubeCheckMethods" :key="lueIndex">
                          {{lueIndex+1}}.方法内容
                          <el-input v-model="lueItem.method" style="width:50%;margin: 0 10px;"></el-input>
                          <span @click="deleteLubeMethod(luItem.lubeCheckMethods, lueItem, lueIndex)" style="cursor: pointer;color: red;">删除</span>
                        </div>
                        <div class="add-checkContent">
                          <span @click="addLubeMethod(luItem.lubeCheckMethods, luItem.lubeContentId)">添加内容</span>
                        </div>
                      </div>
                      <el-drawer
                        title="添加润滑油"
                        :with-header="false"
                        :visible.sync="luItem.drawerLube"
                        direction="rtl"
                        size="800px">
                        <div class="drawer-title" style="font-size:16px;">添加润滑油</div>
                        <div class="drawer-content">
                          <el-form :inline="true" :model="formLube" class="demo-form-inline">
                            <el-form-item label="品牌">
                              <el-select
                                size="small"
                                v-model="formLube.brandId"
                                clearable
                                placeholder=""
                                style="width: 160px">
                                <el-option
                                  v-for="item in brand"
                                  :label="item.brand"
                                  :value="item.id"
                                  :key="item.id"></el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item label="分类">
                              <el-select
                                size="small"
                                v-model="formLube.classificationId"
                                clearable
                                placeholder=""
                                style="width: 160px">
                                <el-option
                                  v-for="item in classification"
                                  :label="item.classification"
                                  :value="item.id"
                                  :key="item.id"></el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item label="等级">
                              <el-select
                                size="small"
                                v-model="formLube.levelId"
                                clearable
                                placeholder=""
                                style="width: 160px">
                                <el-option
                                  v-for="item in level"
                                  :label="item.level"
                                  :value="item.id"
                                  :key="item.id"></el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item>
                              <el-button size="small" type="primary" @click="onLubeSubmit">查询</el-button>
                            </el-form-item>
                          </el-form>
                          <el-table :data="tableLubeData" height="700">
                            <el-table-column label="勾选" width="70">
                              <template slot-scope="scope">
                                <el-radio-group v-model="deviceRadio" @change="checkLubeChange(scope.row)">
                                  <el-radio :label="scope.row.id">{{''}}</el-radio>
                                </el-radio-group>
                              </template>
                            </el-table-column>
                            <el-table-column prop="code" label="编码"></el-table-column>
                            <el-table-column prop="model" label="型号"></el-table-column>
                            <el-table-column prop="specification" label="规格参数"></el-table-column>
                            <el-table-column prop="brand" label="品牌"></el-table-column>
                            <el-table-column prop="classification" label="分类"></el-table-column>
                            <el-table-column prop="level" label="等级"></el-table-column>
                          </el-table>
                        </div>
                        <div class="drawer-bottom">
                          <el-button type="primary" size="small" @click="luItem.drawerLube=false">取消</el-button>
                          <el-button
                            size="small"
                            type="primary"
                            @click="drawerLubeOK(index, luItem, luIndex)"
                            class="drawer-bottom-ok">确 定</el-button>
                        </div>
                      </el-drawer>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </div>
          <div class="bottom-btn">
            <div style="text-align: right;margin-right: 20px;">
              <el-button type="primary" size="small" @click="$router.push('/patrol')">取消</el-button>
              <el-button @click="submit" size="small" type="primary">提交</el-button>
            </div>
          </div>
        </el-form>
      </div>

      <el-drawer
        title="添加设备"
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">添加设备</div>
        <div class="drawer-content">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="类型">
              <el-select
                size="small"
                v-model="formInline.deviceTypeId"
                clearable
                placeholder="请选择类型"
                style="width: 160px">
                <el-option
                  v-for="item in leixin"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属部门">
              <el-select
                size="small"
                v-model="formInline.departmentId"
                clearable
                placeholder="请选择所属部门"
                style="width: 160px">
                <el-option
                  v-for="item in bumen"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负责人">
              <el-select
                size="small"
                v-model="formInline.staffId"
                clearable
                placeholder="请选择负责人"
                style="width: 160px">
                <el-option
                  v-for="item in yuangong"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="tableDataCp"
            border
            height="700">
            <el-table-column label="勾选" width="70">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="materialsCode" label="编码"></el-table-column>
            <el-table-column prop="deviceTypeName" label="分类"></el-table-column>
            <el-table-column
              prop="departmentName"
              label="所属部门"
            ></el-table-column>
            <el-table-column prop="staffName" label="负责人"></el-table-column>
            <el-table-column prop="count" label="数量"></el-table-column>
            <el-table-column prop="useCount" label="用量"></el-table-column>
            <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.num"
                    :controls="false"
                    :min="0"
                    :max="scope.row.count - scope.row.useCount"
                    size="small"
                    :disabled="scope.row.checked"
                  ></el-input-number>
                </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="drawer-bottom">
          <el-button type="primary" size="small" @click="drawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
    
  </div>
</template>

<script>
import wMessage from '../../js/reWriteMessage.js'
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Vue from 'vue'

Vue.component(CollapseTransition.name, CollapseTransition)
export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false,
      rules: {
        patrolName:[{ required: true, message: '必填项', trigger: 'blur' }],
        patrolNumber:[{ required: true, message: '必填项', trigger: 'blur' }],
        departmentId:[{ required: true, message: '必填项', trigger: 'change' }],
        facilityId:[{ required: true, message: '必填项', trigger: 'change' }],
        alias:[{ required: true, message: '必填项', trigger: 'blur' }],
        ein:[{ required: true, message: '必填项', trigger: 'blur' }],
        applyTime:[{type: 'date', required: true, message: '必填项', trigger: 'change' }],
      },
      
      drawer: false, // 设备信息
      patrolDeviceId: [],
      tableData: [],
      tableDataCp: [],
      
      formInline: {}, // 添加设备
      bumen: [],
      leixin: [],
      sheshi: [],
      yuangong: [],
      tableSelectList: [],
      
      formLube: {}, // 添加润滑油
      tableLubeData: [],
      deviceRadio: '',
      selectLube: {},
      model: [],
      brand: [],
      classification: [],
      level: [],

      addOperationContent:[], //已有设备 -> 增加巡点检内容
      addLubeCons: [], //已有设备 -> 增加润滑内容
      addLubeMethods: [], //已有设备 -> 增加润滑方法

      checkContentIds: [], //已有设备 -> 删除巡点检内容
      lubeContentIds: [], //已有设备 -> 删除润滑内容
      lubeMethodIds: [], //已有设备 -> 删除润滑方法
    };
  },
  methods: {
    // 折叠设备
    toggleDevice(item) {
      item.dShows = !item.dShows
    },
    // 点击选择润滑油
    handleModel(dIndex, luItem, luIndex) {
      this.form.patrolDeviceDetails.forEach((v, k)=>{
        if(k == dIndex) {
          v.lubeCheckContents.forEach((it, ind)=>{
            if(ind == luIndex) {
              luItem.drawerLube = true
            }
          })
        }
      })
    },
    // 清除润滑油
    clearLubeOil(dIndex, luItem, luIndex) {
      this.form.patrolDeviceDetails.forEach((v, k)=>{
        if(k == dIndex) {
          v.lubeCheckContents.forEach((it, ind)=>{
            if(ind == luIndex) {
              luItem.brand = ''
              luItem.classification = '' 
              luItem.level = '' 
              this.$forceUpdate()
            }
          })
        }
      })
    },
    // 勾选润滑油
    checkLubeChange(row) {
      this.selectLube = row
    },
    // 润滑油勾选后点击确定
    drawerLubeOK(dIndex, luItem, luIndex) {
      this.form.patrolDeviceDetails.forEach((v, k)=>{
        if(k == dIndex) {
          v.lubeCheckContents.forEach((it, ind)=>{
            if(ind == luIndex) {
              luItem.lubeArchivesId = this.selectLube.id
              luItem.model = this.selectLube.model
              luItem.brand = this.selectLube.brand 
              luItem.classification = this.selectLube.classification 
              luItem.level = this.selectLube.level 
              luItem.drawerLube = false
              this.deviceRadio = ''
            }
          })
        }
      })
    },
    closeConItem(e) {
      // 关闭点检模块（内容删除）
      if(e==false) {
        this.form.patrolDeviceDetails.forEach((v, k) => {
          v.checkRContents.forEach((cItem) => {
            this.checkContentIds.push(cItem.operationCheckId)
          })
        })
        return
      }
      console.log(this.form.patrolDeviceDetails)
      this.form.patrolDeviceDetails.forEach((v, k) => {
        if(v.checkRContents.length == 0) {
          let obj = {
            checkContent: '',
            patrolDeviceId: v.patrolDeviceId,
            taskType: 1
          }
          v.checkRContents.push(obj)
          this.addOperationContent.push(obj)
        }
      })
    },
    closeLubeItem(e) {
      // 关闭润滑模块（内容删除）
      if(e==false) {
        this.form.patrolDeviceDetails.forEach((v, k) => {
          if(v.lubeCheckContents && !v.lube) {
            v.lubeCheckContents.forEach((cItem) => {
              if(cItem.lubeContentId!=null) {
                this.lubeContentIds.push(cItem.lubeContentId)
              }
              cItem.lubeCheckMethods.forEach((mItem) => {
                if(mItem.lubeMethodId!=null) {
                  this.lubeMethodIds.push(mItem.lubeMethodId)
                }
              })
            })
          }
        })
        return
      }
      // 打开润滑模块（默认添加一条）
      this.form.patrolDeviceDetails.forEach((v, k) => {
        if(v.lubeCheckContents == null && v.lube) {
          v.lubeCheckContents = []
          let obj = {
            drawerLube: false,
            lubeArchivesId: '',
            lubeCheckMethods: [
              {
                method: ''
              }
            ],
            patrolDeviceId: v.patrolDeviceId,
            points: '',
            position: '',
            refuel: '',
            replace: ''
          }
          v.lubeCheckContents.push(obj)
          this.addLubeCons.push(obj)
        }
      })
    },
    loadDetail() {
      this.loading = true;
      this.$ajax.post("patrolDetail", {
        id: this.row.id,
      }).then((res) => {
        res.data.patrolDeviceDetails.forEach((v, k) => {
          v.cAlias = v.alias,
          v.cApplyTime = v.applyTime,
          v.cEin = v.ein
          v.dShows = true,
          v.patrol = false,
          v.check = false,
          v.lube = false,
          v.patrolRContents = [],
          v.checkRContents = [],
          v.checkContents.forEach((i, d) => {
            if(i.taskType==0) {
              if(i.checkContent) {
                v.patrol = true
                v.patrolRContents.push(i)
                i.pCon = i.checkContent
                return
              }
            }
            if(i.taskType==1) {
              if(i.checkContent) {
                v.check = true
                v.checkRContents.push(i)
                i.cCon = i.checkContent
                return
              }
            }
          })
          if(v.lubeCheckContents!=null) {
            v.lubeCheckContents.forEach((item, index) => {
              if(item.position) {
                v.lube = true
                item.drawerLube = false
                item.cLubeArchivesId = item.lubeArchivesId
                item.cPoints = item.points
                item.cPosition = item.position
                item.cRefuel = item.refuel 
                item.cReplace = item.replace
                item.lubeCheckMethods.forEach((mItem ,mIndex) => {
                  mItem.cMethod = mItem.method
                })
                return
              }
            })
          }
        })
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.loading = false;
      }).catch(err=>{
        this.loading = false
      })
    }, 
    loadListData() {
      this.$ajax.post("deviceChecklist", {
        departmentId: this.formInline.departmentId,
        deviceTypeId: this.formInline.deviceTypeId,
        staffId: this.formInline.staffId,
      }).then((res) => {
        this.tableData = res.data;
        this.tableDataCp = []
        this.tableData.forEach(item => {
          if(item.count == item.useCount) {
            return
          }
          let d = JSON.parse(JSON.stringify(item));
          d.num = 1
          d.checked = false
          this.tableDataCp.push(d)
        })
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    load_sheshi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.sheshi = res.data;
      });
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    load_bumen() {
      this.$ajax.get("departmentSecondLevel").then((res) => {
        this.bumen = res.data;
      })
    },
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.leixin = res.data;
      });
    },
    load_Model() {
      this.$ajax.post("lubeManager", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.model = res.data;
      });
    },
    load_brand() {
      this.$ajax.post("lubeBrand", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.brand = res.data
      });
    },
    load_classification() {
      this.$ajax.post("lubeClassification", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.classification = res.data
      })
    },
    load_level() {
      this.$ajax.post("lubeLevel", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.level = res.data
      })
    },
    openDeviceDrawer() {
      this.drawer = true;
      this.tableSelectList = []
      this.tableDataCp = []
      this.tableData.forEach(item => {
        if(item.count == item.useCount) {
          return
        }
        let d = JSON.parse(JSON.stringify(item));
        d.num = 1
        d.checked = false
        this.tableDataCp.push(d)
      })
    },
    deleteDevice(item, index) {
      if (item.patrolDeviceId != undefined) {
        this.patrolDeviceId.push(item.patrolDeviceId)
      }
      this.form.patrolDeviceDetails.splice(index, 1);
    },
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    drawerOK() {
      this.tableSelectList.forEach(item => {
        item.modelArr = [];
        for (let i = 0; i < item.num; i++) {
          this.form.patrolDeviceDetails.push({
            deviceName: item.name,
            specification: item.specification,
            departmentName: item.departmentName,
            deviceTypeName: item.deviceTypeName,
            archivesId: item.id,
            dShows: true,
            patrol: true,
            check: false,
            lube: false,
            alias: '',
            ein: '',
            applyTime: '',
            patrolRContents: [{
              checkContent: '',
              taskType: 0
            }],
            checkRContents: [{
              checkContent: '',
              taskType: 1
            }],
            lubeCheckContents: [{
              drawerLube: false,
              lubeArchivesId: '',
              lubeCheckMethods: [
                {
                  method: ''
                }
              ],
              points: '',
              position: '',
              refuel: '',
              replace: ''
            }]
          })
        }
      })
      this.drawer = false;
    },
    onLubeSubmit() {
      this.loadTableData()
    },
    onSubmit() {
      this.loadListData();
    },
    // 添加巡检检查模块
    addCheckContent(arr, patrolDeviceId, num) {
      let obj = {
        checkContent: '',
        patrolDeviceId: patrolDeviceId,
        taskType: num
      }
      arr.push(obj)
      this.addOperationContent.push(obj)
    },
    deleteDate(arr, i, k) {
      this.checkContentIds.push(i.operationCheckId)
      arr.splice(k, 1)
    },
    // 添加润滑模块
    addLube(arr, patrolDeviceId) {
      let obj = {
        drawerLube: false,
        lubeArchivesId: '',
        lubeCheckMethods: [
          {
            method: ''
          }
        ],
        patrolDeviceId: patrolDeviceId,
        points: '',
        position: '',
        refuel: '',
        replace: ''
      }
      arr.push(obj)
      this.addLubeCons.push(obj)
    },
    deleteLube(arr, i, k) {
      this.lubeContentIds.push(i.lubeContentId)
      arr.splice(k, 1)
    },
    // 添加润滑方法模块
    addLubeMethod(arr, lubeContentId) {
      let obj = {
        lubeCheckContentId: lubeContentId,
        method: ''
      }
      arr.push(obj)
      this.addLubeMethods.push(obj)
    },
    deleteLubeMethod(arr, i, k) {
      this.lubeMethodIds.push(i.lubeMethodId)
      arr.splice(k, 1)
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var formData = {
            addLubeCheckContents: [],
            addLubeCheckMethods: [],
            addOperationContents: [],
            addPatrolDevices: [],
            deleteCheckContentIds: [],
            deleteLubeContentIds: [],
            deleteLubeMethodIds: [],
            deletePatrolDeviceIds: [],
            editLubeCheckContents: [],
            editLubeCheckMethods: [],
            editOperationContents: [],
            editPatrolDevices: [],
            id: this.form.id,
            patrolBase: {
              departmentId: this.form.departmentId,
              description: this.form.description,
              facilityId: this.form.facilityId,
              patrolName: this.form.patrolName,
              patrolNumber: this.form.patrolNumber
            }
          }
          // 增加已有设备的巡点检内容
          this.addOperationContent.forEach((oItem, oIndex) => {
            formData.addOperationContents.push({
              checkContent: oItem.checkContent,
              patrolDeviceId: oItem.patrolDeviceId,
              taskType: oItem.taskType
            })
          })
          // 增加已有设备润滑内容
          this.addLubeCons.forEach(v=>{
            if(v.position && v.points && v.lubeArchivesId && v.replace && v.refuel) {
              formData.addLubeCheckContents.push({
                lubeArchivesId: v.lubeArchivesId,
                lubeCheckMethods: v.lubeCheckMethods,
                patrolDeviceId: v.patrolDeviceId,
                points: v.points,
                position: v.position,
                refuel: v.refuel,
                replace: v.replace
              })
            }else {
              formData.addLubeCheckContents = []
            }
          })
          // 增加已有设备润滑方法
          this.addLubeMethods.forEach((mItem, mIndex) => {
            formData.addLubeCheckMethods.push({
              lubeCheckContentId: mItem.lubeCheckContentId,
              method: mItem.method
            })
          })
          // 新增设备
          this.form.patrolDeviceDetails.forEach((pItem, pIndex) => {    
            if(pItem.patrolDeviceId) {
              // 编辑已有设备信息
              if(pItem.cAlias!=pItem.alias || pItem.cApplyTime!=pItem.applyTime || pItem.cEin!=pItem.ein) {
                formData.editPatrolDevices.push({
                  alias: pItem.alias,
                  applyTime: pItem.applyTime,
                  archivesId: pItem.archivesId,
                  ein: pItem.ein,
                  patrolDeviceId: pItem.patrolDeviceId
                })
              }
              // 编辑已有巡点检内容
              pItem.patrolRContents.forEach((i) => {
                if(i.operationCheckId) {
                  if(i.checkContent!=i.pCon){
                    formData.editOperationContents.push({
                      checkContent: i.checkContent,
                      operationCheckId: i.operationCheckId
                    })
                  }
                }
              })
              pItem.checkRContents.forEach((i) => {
                if(i.operationCheckId) {
                  if(i.checkContent!=i.cCon){
                    formData.editOperationContents.push({
                      checkContent: i.checkContent,
                      operationCheckId: i.operationCheckId
                    })
                  }
                }
              })
              // 编辑已有润滑内容
              if(pItem.lubeCheckContents) {
                pItem.lubeCheckContents.forEach((i) => {
                  if(i.lubeContentId && i.lubeArchivesId) {
                    if(i.cLubeArchivesId!=i.lubeArchivesId || i.cPoints!=i.points || i.cPosition!=i.position || i.cRefuel!=i.refuel || i.cReplace!=i.replace) {
                      formData.editLubeCheckContents.push({
                        lubeArchivesId: i.lubeArchivesId,
                        lubeContentId: i.lubeContentId,
                        points: parseInt(i.points),
                        position: i.position,
                        refuel: parseInt(i.refuel),
                        replace: parseInt(i.replace)
                      })
                    }
                  }
                  // 编辑已有润滑方法
                  i.lubeCheckMethods.forEach((mI) => {
                    if(mI.lubeMethodId && mI.method) {
                      if(mI.cMethod!=mI.method) {
                        formData.editLubeCheckMethods.push({
                          lubeMethodId: mI.lubeMethodId,
                          method: mI.method
                        })
                      }
                    }
                  })
                })
              }
              return
            }
            let arr = []
            pItem.patrolRContents.forEach((i)=>{
              arr.push({
                checkContent: i.checkContent,
                taskType: i.taskType
              })
            })
            pItem.checkRContents.forEach((i)=>{
              arr.push({
                checkContent: i.checkContent,
                taskType: i.taskType
              })
            })
            let lubeArr = []
            pItem.lubeCheckContents.forEach((i)=>{
              lubeArr.push({
                lubeArchivesId: i.lubeArchivesId,
                lubeCheckMethods: i.lubeCheckMethods,
                points: i.points,
                position: i.position,
                refuel: i.refuel,
                replace: i.replace
              })
            })
            formData.addPatrolDevices.push({
              alias: pItem.alias,
              applyTime: pItem.applyTime,
              archivesId: pItem.archivesId,
              ein: pItem.ein,
              lubeCheckContents: pItem.lube ? lubeArr : null,
              operationContents: arr
            })
          })

          for(let i=0;i<formData.editOperationContents.length;i++){
            if(!formData.editOperationContents[i].checkContent) return wMessage.error("检查内容不能为空")
          }

          // 判断新增设备 别名和投入时间 必填
          formData.addPatrolDevices.forEach((val, key)=>{
            if(!val.alias) return wMessage.error("设备别名不能为空")
            if(!val.ein) return wMessage.error("设备识别号不能为空")
            if(!val.applyTime) return wMessage.error("请选择设备投入时间")
            if(val.lubeCheckContents) {
              val.lubeCheckContents.forEach((item, index)=>{
                if(!item.position) return wMessage.error("润滑部位不能为空")
                if(!item.points) return wMessage.error("润滑点数不能为空")
                if(!item.lubeArchivesId) return wMessage.error("润滑型号不能为空")
                if(!item.replace) return wMessage.error("换底油用量不能为空")
                if(!item.refuel) return wMessage.error("补加油用量不能为空")
                item.lubeCheckMethods.forEach(i=>{
                  if(!i.method) return wMessage.error("润滑方法不能为空")
                })
              })
            }
          })
          for(let i=0; i<formData.addPatrolDevices.length; i++){
            if(!formData.addPatrolDevices[i].alias || !formData.addPatrolDevices[i].applyTime || !formData.addPatrolDevices[i].ein) {
              return
            }
            if(formData.addPatrolDevices[i].lubeCheckContents) {
              for(let j=0; j<formData.addPatrolDevices[i].lubeCheckContents.length; j++) {
                if(!formData.addPatrolDevices[i].lubeCheckContents[j].position || !formData.addPatrolDevices[i].lubeCheckContents[j].points || !formData.addPatrolDevices[i].lubeCheckContents[j].lubeArchivesId || !formData.addPatrolDevices[i].lubeCheckContents[j].replace || !formData.addPatrolDevices[i].lubeCheckContents[j].refuel) {
                  return
                }
                for(let k=0; k<formData.addPatrolDevices[i].lubeCheckContents[j].lubeCheckMethods.length; k++) {
                  if(!formData.addPatrolDevices[i].lubeCheckContents[j].lubeCheckMethods[k].method) {
                    return
                  }
                }
              }
            }
          }

          for(let i=0;i<formData.addPatrolDevices.length;i++){
            if(formData.addPatrolDevices[i].operationContents) {
              for(let j=0;j<formData.addPatrolDevices[i].operationContents.length;j++){
                if(formData.addPatrolDevices[i].operationContents[j].taskType == 0){
                  if(!formData.addPatrolDevices[i].operationContents[j].checkContent) return wMessage.error("巡检内容不能为空")
                }else {
                  if(!formData.addPatrolDevices[i].operationContents[j].checkContent) return wMessage.error("点检内容不能为空")
                }
              }
            }
          }

          // 判断新增润滑内容 必填
          let device = this.form.patrolDeviceDetails
          for(let i=0;i<device.length;i++) {
            if(device[i].lube && device[i].lubeCheckContents) {
              let lubeCon = device[i].lubeCheckContents
              for(let j=0;j<lubeCon.length;j++) {
                if(!lubeCon[j].position) return wMessage.error("润滑部位不能为空")
                if(!lubeCon[j].points) return wMessage.error("润滑点数不能为空")
                if(!lubeCon[j].lubeArchivesId) return wMessage.error("润滑型号不能为空")
                if(!lubeCon[j].replace) return wMessage.error("换底油用量不能为空")
                if(!lubeCon[j].refuel) return wMessage.error("补加油用量不能为空")
                if(!lubeCon[j].position || !lubeCon[j].points || !lubeCon[j].lubeArchivesId || !lubeCon[j].replace || !lubeCon[j].refuel) {
                  return
                }

                let lubeMethod = lubeCon[j].lubeCheckMethods
                for(let k=0;k<lubeMethod.length;k++) {
                  if(!lubeMethod[k].method) return wMessage.error("润滑方法不能为空")
                  if(!lubeMethod[k].method) {
                    return
                  }
                }
              }
            }
          }

          // 删除已有设备
          this.patrolDeviceId.forEach((idItem, idIndex) => {
            formData.deletePatrolDeviceIds.push(idItem)
          })

          // 删除已有巡点检内容
          formData.deleteCheckContentIds = this.checkContentIds

          // 删除已有润滑内容
          formData.deleteLubeContentIds = this.lubeContentIds

          // 删除已有润滑方法
          formData.deleteLubeMethodIds = this.lubeMethodIds

          this.loading = true
          this.$ajax.post("patrolUpdate", formData).then((res) => {
            this.$message.success("成功")
            this.$router.push("/patrol")
            this.loading = false
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    // 获取润滑油列表
    loadTableData() {
      this.$ajax.post("lubeManager", {
        brandId: this.formLube.brandId,
        classificationId: this.formLube.classificationId,
        levelId: this.formLube.levelId,
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.tableLubeData = res.data;
      });
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/patrol");
    } else {
      this.row = this.$route.params.row;
    }
    this.loadDetail();
    this.load_Model();
    this.load_brand();
    this.load_classification();
    this.load_level();
    this.load_sheshi();
    this.load_leixing();
    this.load_bumen();
    this.load_yuangong();
    this.loadListData();
    this.loadTableData();
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.demo-form-inline {
  padding-top: 20px;
}
.delete-device {
  color: #F56C6C;
  padding: 0;
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.demo-form-inline {
  padding-top: 20px;
}
.drawer-content {
  padding: 0 20px;
}
.device-model {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 10px 20px;
  .title-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    span:nth-last-child(1) {
      color: red;
      cursor: pointer;
      font-weight: 400;
    }
  }
}
.form-title {
  font-weight: bold;
}
.patrol-con {
  font-size: 14px;
  .patrol-title {
    border-left: 0;
    border-top: 1px solid #eee;
    padding: 30px 0 0;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
  }
  .con-item {
    display: flex;
    align-items: center;
    margin: 10px 20px;
  }
}
.lube-con {
  .lube-title {
    border-left: 0;
    border-top: 1px solid #eee;
    padding: 30px 0 0;
    margin: 10px 0;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    .lube-add {
      margin-left: 30px;
      color: #66b1ff;
      cursor: pointer;
    }
  }
  .item-title {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    background-color: #f2f2f2;
    padding: 0 15px;
  }
  .item-con {
    margin: 20px 0;
  }
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
.lube-btn {
  border: none;
  outline: none;
  width: 100%;
  color: #FFF;
  cursor: pointer;
  line-height: 40px;
  border-radius: 4px;
  background: #409EFF;
  border-color: #66b1ff;
}
.lube-btn:hover{
  background: #66b1ff;
  border-color: #66b1ff;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 1px 10px;
  color: #409EFF;
  line-height: 40px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  .close-lube {
    cursor: pointer;
  }
}
.form-box {
  position: relative;
  padding: 50px 50px 60px;
  .bottom-btn {
    width: 46%;
    height: 60px;
    line-height: 60px;
    position: fixed;
    bottom: 22px;
    z-index: 1;
    padding: 0 0 0 20px;
    box-sizing: border-box;
	  backdrop-filter: saturate(50%) blur(8px);
    background: rgba(0, 0, 0, .1);
    border-radius: 6px;
  }
}
</style>
<style lang="less">
#patrolEdit {
  .el-input-number--small {
    width: 60px;
  }
  .el-input-number.is-without-controls .el-input__inner {
    padding: 0 4px;
  }
}
.el-drawer.rtl {
  overflow: scroll;
}
</style>
